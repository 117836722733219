// Local
import { Container, Row, Spacer } from "@nextui-org/react";
import Logo from "../images/logo_transparent.png";

function Landing(): JSX.Element {
  return (
    <Container display="inline">
        <Spacer />
        <Row justify="center">
          <img style={{ width: "35%", height: "auto", maxWidth: "80em" }} src={Logo} alt="Logo" />
        </Row>
        <Row justify="center">
        </Row>
    </Container>
  );
}

export { Landing };
