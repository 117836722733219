import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";

// Local.
import { Navigation } from "./components/navigation";
import { RisingYewTheme } from "./theme";

// Routes.
import { Landing } from "./routes/landing";
import { About } from "./routes/about";
import { Contact } from "./routes/contact";
import { Footer } from "./components/footer";

function App() {
  return (
    <NextUIProvider theme={RisingYewTheme}>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </NextUIProvider>
  );
}

export default App;
