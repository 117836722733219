// External.
import { Button, Navbar, Text } from "@nextui-org/react";
import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../images/logo_transparent.png";

// Local.
const HOME_SECTION = "/";
const ABOUT_SECTION = "/about";
const CONTACT_SECTION = "/contact";

interface Section {
  name: string;
  url: string;
  isButton?: boolean;
}

const SECTIONS: readonly Section[] = [
  { name: "Home", url: "/" },
  //{ name: "About", url: "/about" },
  //{ name: "Contact", url: "/contact", isButton: true },
] as const;

function Navigation(): JSX.Element {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Handle closing the responsive hamburger menu
  // when clicked.
  function onNavClicked() {
    if (!isOpen || !ref.current) {
      return;
    }

    const current = ref.current as HTMLButtonElement;
    current.click();
  }

  return (
    <Navbar isBordered variant="static">
      <Navbar.Brand>
        <Navbar.Toggle
          ref={ref}
          showIn="sm"
          aria-label="toggle navigation"
          onChange={(isSelected) => setIsOpen(isSelected as boolean)}
        />

        <Link to="/">
          <img style={{ width: "30px", height: "auto", paddingRight: "0.5em" }} src={Logo} alt="Logo" />
        </Link>
        
        <Text b color="inherit">
          <Link to="/">Rising Yew Therapy</Link>
        </Text>
      </Navbar.Brand>
      <Navbar.Content hideIn="sm" variant="underline-rounded">
        {SECTIONS.map((section, index) => {
          if (section.isButton) {
            return (
              <Navbar.Item
                key={`nav-item-${index}`}
                isActive={location.pathname === section.url}
              >
                <Button auto flat onPress={() => navigate(section.url)}>
                  {section.name}
                </Button>
              </Navbar.Item>
            );
          }

          return (
            <Navbar.Item
              key={`nav-item-${index}`}
              isActive={location.pathname === section.url}
            >
              <Link to={section.url}>{section.name}</Link>
            </Navbar.Item>
          );
        })}
      </Navbar.Content>
      <Navbar.Collapse>
        {SECTIONS.map((section, index) => {
          if (section.isButton) {
            return (
              <Navbar.CollapseItem
                key={`nav-item-${index}`}
                isActive={location.pathname === section.url}
              >
                <Button
                  auto
                  flat
                  onPress={() => {
                    onNavClicked();
                    navigate(section.url);
                  }}
                >
                  {section.name}
                </Button>
              </Navbar.CollapseItem>
            );
          }

          return (
            <Navbar.CollapseItem
              key={`nav-item-${index}`}
              isActive={location.pathname === section.url}
            >
              <Link to={section.url} onClick={() => onNavClicked()}>
                {section.name}
              </Link>
            </Navbar.CollapseItem>
          );
        })}
      </Navbar.Collapse>
    </Navbar>
  );
}

export { Navigation };
