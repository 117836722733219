import {
  Container,
  Input,
  Row,
  Spacer,
  Text,
  useInput,
} from "@nextui-org/react";

function validateEmail(email: string) {
  return email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
}

function Contact(): JSX.Element {
  const { value, reset, bindings } = useInput("");

  return (
    <Container
      fluid
      style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "5em" }}
    >
      <Text h1>Contact Us</Text>
      <Text>
        We would love to hear from you. Here at Little Raptors Farm, we believe
        in growing and supporting the community and the first part of any
        meaningful process is communication. Send a message asking questions,
        with any special requests, or just to say hi!
      </Text>
      <Spacer />

      <Row justify="center">
        <Text>Email us at littleraptorsfarmer@gmail.com</Text>
      </Row>
    </Container>
  );
}

export { Contact };
