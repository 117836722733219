import { createTheme } from "@nextui-org/react";

const RisingYewTheme = createTheme({
  type: "light",
  theme: {
    colors: {
      background: "#FAF9F6",
      link: "$green700",

      primaryLight: "$green50",
      primaryLightHover: "$green100",
      primaryLightActive: "$green200",
      primaryLightContrast: "$green600",
    },
  },
});

export { RisingYewTheme };
