import { Button, Container, Spacer, Text } from "@nextui-org/react";
import { useNavigate } from "react-router";

function About(): JSX.Element {
  const navigate = useNavigate();

  const p1 =
    "Rome was not built in a day, as they say. We are still deep in the building phase of our flock and breeding program. It is our belief that strong lineages come from careful planning, deliberate acts, and expansive amounts of empathy and compassion. We are in no rush to simply begin making money at the expense of the quality of the product we produce or the quality of the animals' lives that we are stewards of. Keep an eye on this page for more information as we grow our flocks, facilities, and offerings.";
  const p2 =
    "We believe the quality of the animals' lives, experiences, and inevitable deaths trump all other requirements. We want our animals to live full, beautiful lives filled with joyful moments and experiences. This is the first guiding philosophy used at Little Raptors Farms.";
  const p3 =
    "When you step into a grocery store and examine the shelves, you will find an optimized machine for producing marketable foods. These foods have been designed to produce the highest profits possible; but, in doing so, many have sacrificed nutrition, taste, and the quality of the animals' life (when it is an animal).";
  const p4 =
    "Importantly, this experience of short lived animals suffering through small spaces and high volumes of drugs is a fairly new act in human consumption. As such, we choose only heritage breeds that grow slowly before harvest, that can forage, and that are capable of raising their young with as minimal intervention as possible.";
  const p5 =
    "Finally, we believe in giving back to our community. Strong communities build strong families, relationships, and create maintainable lives. Once we are established, we want to provide education and means for families to become self sufficient without causing animal suffering. We want to foster independence while also cultivating collaboration.";

  return (
    <Container
      fluid
      style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "5em" }}
    >
      <Text h1>About Our Farm</Text>
      <Text h2>Our Progress</Text>
      <Text>{p1}</Text>
      <Spacer />
      <Button auto flat onPress={() => navigate("/contact")}>
        Contact Us
      </Button>
      <Spacer />
      <Text h2>Our Philosophy</Text>
      <Text h3>Life First</Text>
      <Text>{p2}</Text>
      <Spacer />
      <Text h3>Preservation of Heritage</Text>
      <Text>{p3}</Text>
      <Spacer />
      <Text>{p4}</Text>
      <Spacer />
      <Text h3>Community</Text>
      <Text>{p4}</Text>
      <Spacer />
    </Container>
  );
}

export { About };
